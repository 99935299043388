body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Onboard-form{
  width: 30%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* .card-deck{  
  
} */

.row-container{
  display: flex;
  flex-direction: column;
  height: 30vh;
  justify-content: space-around;
  margin-left: auto;
  margin-right: auto;  
}

.button-row{
  display: flex;  
  justify-content: space-between;
}

::-webkit-input-placeholder {
  text-align: center;
  }

  .input{
    padding: 1rem;
  }
